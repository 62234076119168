import { Component, Input } from '@angular/core';
import { IOrganisation } from 'src/app/views/user-management/model/user-management.model';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html'
})
export class MainLayoutComponent {
  @Input() userOrganisation: IOrganisation;

  _stickBreadcrumbs: boolean = true;

  @Input() set sticky(value) {
    // it's undefined by default
    if (value !== undefined) {
      this._stickBreadcrumbs = value
    } else {
      // default it to true for same behaviour as before
      this._stickBreadcrumbs = true;
    }
  }

  get sticky() {
    return this._stickBreadcrumbs;
  }
}
